import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//获取用户列表
export function getUserList(params) {
    return request.get('/userManage/getUserManagementList?' + objectToQueryString(params))
}

//获取区域列表
export function getRegionList(params) {
    return request.post('/common/searchRegionList?' + objectToQueryString(params))
}

//获取用户卡流
export function getUserCardFlow(params) {
    return request.get('/userManage/getUserCardFlow?' + objectToQueryString(params))
}

//获取用户基本信息
export function getBasicUserInformation(params) {
    return request.get('/userManage/getBasicUserInformation?' + objectToQueryString(params))
}

//获取用户车辆列表
export function getUserVehicleList(params) {
    return request.get('/userManage/userBoundVehicleInformationList?' + objectToQueryString(params))
}

//获取用户长租列表
export function getUserLeaseList(params) {
    return request.get('/userManage/getUserLease?' + objectToQueryString(params))
}

//获取用户充值列表
export function getUserRechargeList(params) {
    return request.get('/userManage/getUserRechargeRecords?' + objectToQueryString(params))
}

//根据用户Id获取用户停车订单
export function getUserParkingOrderList(params) {
    return request.get('/userManage/getUserParkingOrder?' + objectToQueryString(params))
}

//根据用户Id获取用户缴费记录
export function getUserPaymentRecords(params) {
    return request.get('/userManage/getUserPaymentRecords?' + objectToQueryString(params))
}

//根据用户Id获取用户优惠券信息
export function getUserCouponList(params) {
    return request.get('/userManage/getUserCouponList?' + objectToQueryString(params))
}

//商户管理列表查询
export function merchantControllerList(params) {
    return request.post('/user/merchantManagerPage?' + objectToQueryString(params))
}

//商户启用
export function enableMerchant(params) {
    return request.post('/user/enableMerchant?' + objectToQueryString(params))
}

//商户禁用
export function disableMerchant(params) {
    return request.post('/user/disableMerchant?' + objectToQueryString(params))
}

//商户充值
export function topSucceed(data) {
    return request.post('/merchantControllerAdministrator/topSucceed', data)
}

//发券配置
export function curledHail(data) {
    return request.post('/merchantControllerAdministrator/curledHail', data)
}

//查询发券配置
export function getPublishCouponTypeByUserId(id) {
    return request.get('/merchantControllerAdministrator/getPublishCouponTypeByUserId?id=' + id)
}

//查询发券记录
export function selectCurledHailLog(id, pageNo, pageSize) {
    return request.get('/merchantControllerAdministrator/selectCurledHailLog?id=' + id + '&pageNo=' + pageNo + '&pageSize=' + pageSize)
}

//查询商户详情
export function selectCommercialTenantParticulars(id) {
    return request.post('/user/merchantDetail?userId=' + id)
}

//审核商户信息
export function audit(userAuditId, verifyStatus) {
    return request.post('/merchantControllerAdministrator/audit?userAuditId=' + userAuditId + '&verifyStatus=' + verifyStatus)
}

//查询当前关联车场信息
export function findMerchantInParkingLot(id) {
    return request.post('/user/findMerchantInParkingLot?id=' + id)
}

//关联车场
export function merchantInParkingLot(data) {
    return request.post('/user/merchantInParkingLot?'+ objectToQueryString(data))
}

export  function delMerchantInParkingLot(id){
    return request.post('/user/delMerchantInParkingLot?id='+id)
}


// 查询余额
export function merchantAccountDetail(id) {
    return request.post('/user/merchantAccountDetail?userId='+ id)
}

// 修改余额
export function merchantAccountCharge(data) {
    return request.post('/user/merchantAccountCharge?'+ objectToQueryString(data))
}

//关联车场
export function getMerchantAccountChargePage(data) {
    return request.post('/merchant/merchantAccountChargePage?'+ objectToQueryString(data))
}


// 岗亭列表
export function guardPostPage(data) {
    return request.post('/settlement/guardPostPage?'+ objectToQueryString(data))
}

// 岗亭详情
export function guardPostInfo(data) {
    return request.post('/settlement/guardPostInfo?'+ objectToQueryString(data))
}

// 新增岗亭
export function addGuardPost(data) {
    return request.post('/settlement/addGuardPost?'+ objectToQueryString(data))
}

// 编辑岗亭
export function editGuardPost(data) {
    return request.post('/settlement/editGuardPost?'+ objectToQueryString(data))
}

// 删除岗亭
export function delGuardPost(data) {
    return request.post('/settlement/delGuardPost?'+ objectToQueryString(data))
}

// 修改密码
export function guardPostChangePwd(data) {
    return request.post('/settlement/guardPostChangePwd?'+ objectToQueryString(data))
}