<template>
    <div>
        <!-- <el-button @click="handleAdd" type="primary">添加分润方</el-button> -->
        <div class="add_table">
            <div class="table_box">
                <div class="table_name table_text table_title">
                    临停分润方
                </div>
                <div class="table_main table_text table_title">
                    分润
                    <span class="table_tip">临停分润比例相加总数必须为100%</span>
                </div>
                <div class="table_del table_text table_title">
                    操作
                </div>
            </div>
            <div class="table_box" v-for="item, index in model.accountRule" :key="index">
                <div class="table_name table_text table_title">
                    <!-- <el-input v-model="item.name" placeholder="请输入分润方名称"></el-input> -->
                    <el-select v-model="item.settleId" placeholder="请选择分润方">
                        <el-option v-for="it, i in settlementList" :key="i" :value="it.userId"
                            :label="it.settlementName"></el-option>
                    </el-select>
                </div>
                <div class="table_main table_text table_title">
                    <div style="display: flex;align-items: center;">
                        <el-select v-model="item.type">
                            <el-option value="0" label="比例（%）"></el-option>
                            <el-option value="1" label="定额（元）"></el-option>
                        </el-select>
                        <el-input v-if="item.type == 0" v-model="item.proportion"></el-input>
                        <el-input v-else v-model="item.amount"></el-input>
                    </div>
                </div>
                <div class="table_del table_text table_title">
                    <el-button size="small common-text" type="text"
                    @click="handleAdd" v-if="index == model.accountRule.length - 1">新增
                    </el-button>
                    <el-button size="small common-text" type="text" style="color: #EA5504 !important;"
                        @click="doDelect(item, index)" v-if="model.accountRule.length > 1">删除
                    </el-button>
                </div>
            </div>
            <el-button class="xxd-button_save" style="margin-top: 50px;" type="primary" @click="save">保存
            </el-button>
        </div>
        <!-- <div class="add_table">
            <div class="table_box">
                <div class="table_name table_text table_title">
                    长租分润方
                </div>
                <div class="table_main table_text table_title">
                    分润
                    <span class="table_tip">长租分润比例相加总数必须为100%</span>
                </div>
                <div class="table_del table_text table_title">
                    操作
                </div>
            </div>
            <div class="table_box" v-for="item, index in modelLease.accountRule" :key="index">
                <div class="table_name table_text table_title">
                    <el-select v-model="item.settleId" placeholder="请选择分润方">
                        <el-option v-for="it, i in settlementList" :key="i" :value="it.userId"
                            :label="it.settlementName"></el-option>
                    </el-select>
                </div>
                <div class="table_main table_text table_title">
                    <div style="display: flex;align-items: center;">
                        <el-select v-model="item.type">
                            <el-option value="0" label="比例（%）"></el-option>
                            <el-option value="1" label="定额（元）"></el-option>
                        </el-select>
                        <el-input v-if="item.type == 0" v-model="item.proportion"></el-input>
                        <el-input v-else v-model="item.amount"></el-input>
                    </div>
                </div>
                <div class="table_del table_text table_title">
                    <el-button size="small common-text" type="text"
                    @click="handleAddLease" v-if="index == modelLease.accountRule.length - 1">新增
                    </el-button>
                    <el-button size="small common-text" type="text" style="color: #EA5504 !important;"
                        @click="doDelectLease(item, index)" v-if="modelLease.accountRule.length > 1">删除
                    </el-button>
                </div>
            </div>
            <el-button class="xxd-button_save" style="margin-top: 50px;" type="primary" @click="saveLease">保存
            </el-button>
        </div> -->
    </div>
</template>
<script>
import {
    searchRule,
    accountRuleEdit
} from "@/api/price";
import {
    settlementPage
} from "@/api/settlementManage"
import { Message } from "element-ui";
export default {
    name: 'parking_give',
    props: {
        parkId: String
    },
    data() {
        return {
            addList: [
                { name: "", type: "", num: "" },
            ],
            model: {
                parkingLotId: "",
                type:0,
                accountRule: [
                    { settleId: "", type: "0", proportion: "", amount: "" }
                ]
            },
            modelLease:{
                parkingLotId: "",
                type:1,
                accountRule: [
                    { settleId: "", type: "0", proportion: "", amount: "" }
                ]
            },
            settlementList: [],
        }
    },
    methods: {
        handleAdd() {
            const data = this.model.accountRule[this.model.accountRule.length - 1];
            if (data.settleId && (data.proportion || data.amount)) {
                this.model.accountRule.push({ settleId: "", type: "0", proportion: "", amount: "" });
            } else {
                return Message.info("请填写完整结算方信息");
            }
        },
        doDelect(item, index) {
            this.modelLease.accountRule.splice(index, 1);
        },
        handleAddLease() {
            const data = this.modelLease.accountRule[this.modelLease.accountRule.length - 1];
            if (data.settleId && (data.proportion || data.amount)) {
                this.modelLease.accountRule.push({ settleId: "", type: "0", proportion: "", amount: "" });
            } else {
                return Message.info("请填写完整结算方信息");
            }
        },
        doDelectLease(item, index) {
            this.modelLease.accountRule.splice(index, 1);
        },
        settlementPage() {
            settlementPage({ pageNo: 1, pageSize: 100 }).then(res => {
                if (res.code === 30 && res.result) {
                    this.settlementList = res.returnObject.records;
                }
            })
        },
        save() {
            // const data = this.model.accountRule[0];
            // if (!data.settleId) {
            //     if (!data.proportion && !data.amount) {
            //         return Message.info("请填写完整第一条结算方信息");
            //     }
            // }
            let isOk = true;
            let allProport = 0;
            this.model.accountRule.forEach((item)=>{
                if(item.type == 0){
                    allProport += Number(item.proportion) || 0;
                }
                if(!item.settleId || (item.type == 0 && !item.proportion) || (item.type == 1 && !item.amount)){
                    isOk = false;
                }
                // else if(item.type == 0 && !item.proportion){
                //     isOk = false;
                // }else if(item.type == 1 && !item.amount){
                //     isOk = false;
                // }
            })
            if(!isOk){
                return Message.info("请填写完整所有结算方信息");
            }
            if(allProport != 100){
                return Message.info("临停分润比例相加总数必须为100%");
            }
            this.model.type = 0;
            accountRuleEdit(this.model).then(res => {
                if (res && res.code == 30) {
                    Message.success("操作成功");
                }
            })
        },
        saveLease() {
            let isOk = true;
            let allProport = 0;
            this.modelLease.accountRule.forEach((item)=>{
                if(item.type == 0){
                    allProport += Number(item.proportion) || 0;
                }
                if(!item.settleId || (item.type == 0 && !item.proportion) || (item.type == 1 && !item.amount)){
                    isOk = false;
                }
            })
            if(!isOk){
                return Message.info("请填写完整所有结算方信息");
            }
            if(allProport != 100){
                return Message.info("长租分润比例相加总数必须为100%");
            }
            this.modelLease.type = 1;
            accountRuleEdit(this.modelLease).then(res => {
                if (res && res.code == 30) {
                    Message.success("操作成功");
                }
            })
        },
        goSearch() {
            const parkId = this.$route.query.parkingId || this.parkId;
            if (!parkId) {
                return;
            }
            // 临停分润
            searchRule({ parkingLotId: parkId,type:0 }).then((res) => {
                if (res && res.code === 30 && res.result) {
                    const returnObject = res.returnObject;
                    
                    if (!returnObject.parkingLotId) {
                        returnObject.parkingLotId = this.$route.query.parkingId || this.parkId
                    }
                    if (!returnObject.accountRuleDetail || returnObject.accountRuleDetail.length == 0) {
                        returnObject.accountRule = [{ settleId: "", type: "0", proportion: "", amount: "" }];
                    }else{
                        returnObject.accountRule = returnObject.accountRuleDetail;
                    }
                    this.model = returnObject;

                }
            });
            // 长租分润
            searchRule({ parkingLotId: parkId,type:1 }).then((res) => {
                if (res && res.code === 30 && res.result) {
                    const returnObject = res.returnObject;
                    
                    if (!returnObject.parkingLotId) {
                        returnObject.parkingLotId = this.$route.query.parkingId || this.parkId
                    }
                    if (!returnObject.accountRuleDetail || returnObject.accountRuleDetail.length == 0) {
                        returnObject.accountRule = [{ settleId: "", type: "0", proportion: "", amount: "" }];
                    }else{
                        returnObject.accountRule = returnObject.accountRuleDetail;
                    }
                    this.modelLease = returnObject;

                }
            });
        },
    },
    mounted() {
        this.settlementPage();
        this.goSearch();
    },
}
</script>
<style scoped lang='less'>
.add_table {
    margin-top: 20px;

    .table_box {
        display: flex;
        align-items: center;
    }

    .table_name {
        width: 510px;
    }

    .table_main {
        width: 509px;
    }

    .table_del {
        width: 509px;
    }

    .table_text {
        font-weight: 600;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.88);
        line-height: 22px;
        text-align: left;
    }

    .table_tip {
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        line-height: 20px;
    }

    .table_title {
        margin: 10px 10px 10px 0px;
        padding-left: 10px;
        border-left: 1px solid rgba(0, 0, 0, 0.06);
        background-color: #FAFAFA;
    }
}
</style>