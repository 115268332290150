<template>
  <div v-loading="isLoading" class="page">
    <div class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
      <div style="background: #0768FD;color: white;display: flex;
            align-items: center;justify-content: center;cursor: pointer;"
           class="width110 font-size16 border-radius4004"
           @click="goBack">
        返回
      </div>
      <div v-if="this.parkingId==''"
           style="color: black;font-weight: bold;display: flex; align-items: center;"
          class="font-size16 margin-left30">
        添加车场
      </div>
      <div v-else style="color: black;font-weight: bold;display: flex; align-items: center;"
          class="font-size16 margin-left30">
      编辑车场
    </div>
    </div>
    <el-tabs
        v-model="currentTab"
        :before-leave="onBeforeLeave"
        type="border-card"
    >
      <el-tab-pane label="基础信息" name="basic">
        <AddParkingBaisc
            :parkingModelJSON="parkingModelJSON"
            @success="onSuccess"
        />
      </el-tab-pane>
<!--      <el-tab-pane label="分润规则" name="share">-->
<!--        <ShareRules-->
<!--            :parkingId="parkingId"-->
<!--            :parkingName="parkingName"-->
<!--        />-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="运营规则" name="operation">-->
<!--        <Operation-->
<!--            :parkingId="parkingId"-->
<!--            :parkingModelJSON="parkingModelJSON"-->
<!--        />-->
<!--      </el-tab-pane>-->
      <el-tab-pane label="分润规则" name="give">
        <Give :parkId="parkingId"/>
      </el-tab-pane>
      <el-tab-pane label="计费规则" name="price">
        <Price :parkId="parkingId"/>
      </el-tab-pane>
<!--      <el-tab-pane label="扩展信息" name="extension">-->
<!--        <Extension-->
<!--            :parkingId="parkingId"-->
<!--            :parkingModelJSON="parkingModelJSON"-->
<!--            @success="onExtensionSuccess"-->
<!--        />-->
<!--      </el-tab-pane>-->
      <!-- <el-tab-pane label="通道配置" name="passage">
        <Passage :parkingId="parkingId" />
      </el-tab-pane> -->
    </el-tabs>
<!--    <AddParkingBaisc-->
<!--        :parkingModelJSON="parkingModelJSON"-->
<!--        @success="onSuccess"-->
<!--    />-->
  </div>
</template>

<script>
import AddParkingBaisc from "./components/basic.vue";
// import ShareRules from "./components/share.vue";
// import Operation from "./components/operation.vue";
// import Extension from "./components/extension.vue";
// import Price from "./components/price.vue";
import {getParkingDetail} from "@/api/parking";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";
// import ShareRules from "@/pages/parking/components/share.vue";
// import Operation from "@/pages/parking/components/operation.vue";
import Price from "@/pages/parking/components/price.vue";
import Give from "@/pages/parking/components/give.vue";
// import Extension from "@/pages/parking/components/extension.vue";
// import {departInspector} from "@/api/patrolInspection";

export default {
  name: "add-parking",
  components: {
    // Extension,
    Price,
    Give,
    // Operation,
    // ShareRules,
    AddParkingBaisc,
    // ShareRules,
    // Operation,
    // Extension,
    // Price,
  },
  data() {
    return {
      parkingId: "",
      parkingName: "",
      currentTab: "basic",
      type: "add",
      parkingModelJSON: "",
      isLoading: false,
    };
  },
  methods: {
    //返回上一页
    goBack() {
      this.$confirm(this.parkingId==''?"确定放弃添加车场吗？":"确定放弃编辑车场吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        var item =  {
          "fullPath": "/parking_manage/add?motionType=0",
          "hash": "",
          "meta": {
            "requiresAuth": true,
            "isAdmin": true,
            "icon": "el-icon-bell",
            "hidden": true,
            "title": "停车场信息维护"
          },
          "name": "add",
          "params": {},
          "path": "/parking_manage/add",
          "query": {
            "motionType": "0"
          }
        };
        this.$store.commit("app/removeRoute", item);
        this.$router.back();
      });
    },
    onBeforeLeave() {
      const parkingId = this.$route.query.parkingId;
      if (!this.parkingId && !parkingId) {
        this.$message({
          message: "请先完成车场基本信息",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    async getParkingDetail(parkingId) {
      this.isLoading = true;
      const res = await getParkingDetail(parkingId);
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        const returnObject = res.returnObject;
        this.parkingName = returnObject.name;
        console.log(returnObject)
        this.parkingModelJSON = JSON.stringify(returnObject);
      }
    },
    onSuccess(e) {
      console.log(e)
      // var item =  {
      //   "fullPath": "/parking_manage/add?motionType=0",
      //   "hash": "",
      //   "meta": {
      //     "requiresAuth": true,
      //     "isAdmin": true,
      //     "icon": "el-icon-bell",
      //     "hidden": true,
      //     "title": "停车场信息维护"
      //   },
      //   "name": "add",
      //   "params": {},
      //   "path": "/parking_manage/add",
      //   "query": {
      //     "motionType": "0"
      //   }
      // };
      // this.$store.commit("app/removeRoute", item);
      // this.$router.back();
      if(e){
        this.parkingId = e
      }
      // this.currentTab = 'price'
      // parkingId=947916615315943424&parkingName=智能产业园测试&motionType=0
    },
    onExtensionSuccess() {
      this.getParkingDetail(this.parkingId);
    },
  },
  mounted() {
    checkToken()
        .then(res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            const parkingId = this.$route.query.parkingId;
            if (parkingId) {
              this.parkingId = parkingId;
              this.getParkingDetail(this.parkingId);
            }
          }
        })
  },
};
</script>

<style lang="less" scoped>
.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
