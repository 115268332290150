import request from '@/utils/request'
import {objectToQueryString} from "@/utils/utils";

//分页获取计费规则
export function getCalcTypePage(pageNo, parkId, pageSize) {
    return request.post('/calculationLogic/getCalcTypePage?pageNo=' + pageNo + '&parkId=' + parkId + '&pageSize=' + pageSize)
}

//开启和禁用计费规则
export function calculTypeEnble(body) {
    return request.post('/calculationLogic/calculTypeEnble?' + objectToQueryString(body))
}

// 计费规则生效
export function editcalculType(body) {
    return request.post('/calculationLogic/calculReload?' + objectToQueryString(body))
}


//查询计费规则详情
export function getCalcDetailsByCalcTypeId(calcTypeId) {
    return request.post('/calculationLogic/searchCalcDetails?id=' + calcTypeId)
}

//保存计费规则-主表信息
export function saveCalcType(body) {
    return request.post('/calculationLogic/addCalcType?' + objectToQueryString(body))
}

//保存车场计费规则详情
export function saveCalcRule(body) {
    return request.post('/calculationLogic/saveCalcRule', body)
}

//保存免费时长
export function saveCalcFree(body) {
    return request.post('/calculationLogic/saveCalcFree', body)
}

//保存特殊免费时长
export function saveCalcSpFree(body) {
    return request.post('/calculationNewLogicController/saveCalcSpFree', body)
}

//保存封顶规则
export function saveCalcMaxLimit(body) {
    return request.post('/calculationLogic/saveCalcMaxLimit', body)
}

//保存特殊封顶规则
export function saveCalcSpMaxLimit(body) {
    return request.post('/calculationNewLogicController/saveCalcSpMaxLimit', body)
}

//保存预支付时长
export function saveCalcPrepay(body) {
    return request.post('/calculationLogic/saveCalcPrepay', body)
}

//模拟计费  in 保留字符待确认
export function chargeFee(parkingLotId, autoId, plateType, inTime, outTime, simulate) {
    return request.post('/calculationLogic/chargeFee?parkingLotId=' + parkingLotId + '&autoId=' + autoId + '&plateType=' + plateType + '&inTime=' + inTime + '&outTime=' + outTime + '&simulate=' + simulate)
}

//删除计费策略
export function deleteCalcTypeById(id) {
    return request.post('/calculationLogic/deleteCalcType?id=' + id)
}


// 查询分润规则
export function searchRule(body) {
    return request.post('/wealthCenter/searchRule?' + objectToQueryString(body))
}

// 编辑分润规则
export function accountRuleEdit(body) {
    return request.post('/wealthCenter/AccountRuleEdit',body)
}