import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

// 查询停车场信息
export function getParkingList(params) {
    return request.post('/monitor/searchParkingLotMinitorList?' + objectToQueryString(params));
}

// 禁用/启用车场
export function updateParkingLotStatus(params) {
    return request.get('/parkingLotManagementController/updateParkingLotStatus?' + objectToQueryString(params));
}

/**
 * 根据ID获取停车场
 * @param {*} ParkingLotId
 * @returns
 */
export function getParkingDetail(ParkingLotId) {
    return request.post('/operation/parkingLotDetail?id=' + ParkingLotId)
}

/**
 * 导出车场列表
 * @param {*} params
 * @returns
 */
export function exportParkingLotInfo(params) {
    return request.get('/parkingLotManagementController/exportParkingLotInfo?' + objectToQueryString(params))
}

/**
 * 创建停车场基础信息
 * @param {*} params
 * @returns
 */
export function createParkingLotInfo(params) {
    return request.post('/operation/parkingLotAdd?' + objectToQueryString(params))
}

/**
 * 编辑停车场基础信息
 * @param {*} params
 * @returns
 */
export function updateParkingLotInfo(params) {
    return request.post('/operation/parkingLotUpdate?' + objectToQueryString(params))
}

/**
 * 上传图片
 * @param {*} params
 * @returns
 */
export function doUploadImage(params) {
    return request.post('/common/addPhoto', params);
}

/**
 * 获取图片附件
 * @param {*} attachmentId
 * @returns
 */
export function getParkingImage(attachmentId) {
    return request.post('/common/getPhotoUrl?id=' + attachmentId)
}

export function createAndUpdateOperatingRules(params) {
    return request.post('/parkingLotManagementController/createAndUpdateOperatingRules', params);
}

export function saveExtension(params) {
    return request.post('/parkingLotManagementController/createAndUpdateAttrInfo', params);
}

export function createGangtingDevice(params) {
    return request.post('/DeviceChannleController/createGangtingDevice', params);
}

export function deleteGangtingDevice(id) {
    return request.get('/DeviceChannleController/deleteGangtingDevice?id=' + id);
}

export function getGangtingDeviceList(parkingId) {
    return request.get('/DeviceChannleController/getGangtingDeviceList?parkingId=' + parkingId);
}

export function getParkingLicenseByPage(params) {
    return request.get('/parkingLotManagementController/findParkingLicenceForPage?' + objectToQueryString(params));
}

//创建停车场分润规则
export function createSettlementConfig(params) {
    return request.post('/parkingLotManagementController/createSettlementConfig', params)
}

//获取停车场当前分润规则
export function getSettlementConfigByObjectId(parkingId) {
    return request.get('/parkingLotManagementController/getSettlementConfigByObjectId?objectId=' + parkingId)
}

//根据id获取停车场信息
export function getParkingLotInfoById(ParkingId) {
    return request.get('/parkingLotManagementController/getParkingLotInfoById?parkingId=' + ParkingId)
}

// 获取语音播报设置
export function showManagerSelect(params) {
    return request.post('/operation/parkingLotGateShowManagerSelect?' + objectToQueryString(params))
}

// 语音播报设置
export function editShowManager(params) {
    return request.post('/operation/parkingLotGateShowManager?' + objectToQueryString(params))
}

// 追缴订单查询
export function recoveryParkingOrderQuery(params) {
    return request.post('/unParkingOrder/recoveryParkingOrderQuery?' + objectToQueryString(params))
}

// 欠费追缴
export function etcRecoveryParkingOrderPay(params) {
    return request.post('/unParkingOrder/etcRecoveryParkingOrderPay?' + objectToQueryString(params))
}

// 放弃追缴
export function removeRecoveryParkingOrder(params) {
    return request.post('/unParkingOrder/removeRecoveryParkingOrder?' + objectToQueryString(params))
}